import { clsx } from 'clsx';
import Panel from '@daouoffice/ui/lib/foundation/Panel';

import { useOrganizerContext } from './Context';
import { UnfoldButton } from './unfold-button';
import OrganizerDefaultApp from './default-app';
import { useOrganizerValuesAtom } from './Provider';
import DefaultMenuList from './default-menu-list';
import ToolItemList from './tool-item-list/tool-item-list';
import { ShortcutItemList } from './shortcut-list';
import { ORGANIZER_MIN_HEIGHT } from './constants';

import styles from './organizer-body.module.css';

export function Body() {
  const ctx = useOrganizerContext();
  const organizerAtom = useOrganizerValuesAtom();
  return (
    <div
      className={clsx(styles.organizer, {
        flip: organizerAtom.organizerState === 'flip',
      })}
      style={{ minHeight: ORGANIZER_MIN_HEIGHT }}
    >
      <Panel
        usePortal
        width={350}
        className="mt-[80px] h-[calc(100%-80px)] right-[80px]"
        direction="right"
        open={organizerAtom.panelState !== 'none'}
      >
        <OrganizerDefaultApp />
      </Panel>

      <div
        className={clsx(styles.wrap_organizer, {
          hidden: organizerAtom.organizerState === 'flip',
        })}
        style={{ minHeight: ORGANIZER_MIN_HEIGHT }}
      >
        <DefaultMenuList />
        <p className={styles.divider} />
        <ShortcutItemList
          shortcutItemList={ctx.shortcutItems}
          className={styles.group_link_list}
        />

        <ToolItemList />
      </div>
      <UnfoldButton />
    </div>
  );
}

export default Body;
