/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface StackContextType<T> {
  stack: T[];
  setStack: Dispatch<SetStateAction<T[]>>;
}

const StackContext = createContext<StackContextType<any>>({
  stack: [],
  setStack: () => {},
});

export interface Props<T> {
  defaultPage: T;
}

export function Root<T extends string>({
  defaultPage,
  children,
}: PropsWithChildren<Props<T>>) {
  const [stack, setStack] = useState<T[]>([defaultPage]);

  const values: StackContextType<T> = useMemo(
    () => ({
      stack,
      setStack,
    }),
    [stack, setStack],
  );

  return <StackContext value={values}>{children}</StackContext>;
}

export default Root;

export function useStack<T>(): StackContextType<T> {
  return useContext(StackContext) as StackContextType<T>;
}
