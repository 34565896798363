import { PropsWithChildren, createContext, useContext } from 'react';

export interface Props<T> {
  name: string;
  idKey: string;
  data?: T;
}

export interface IDnDContexts<T> {
  name: string;
  idKey: string;
  data?: T;
  getData: (
    item: Record<string | symbol, unknown>,
  ) => Record<string | symbol, unknown>;

  dataValidation: (
    data: Record<string | symbol, unknown>,
    symbol: string,
  ) => boolean;
}

function createDefaultDnDContext<T>(): IDnDContexts<T> {
  return {
    name: '',
    idKey: '',
    data: undefined,
    dataValidation: () => false,
    getData: () => ({}),
  };
}

export const DndContext = createContext<IDnDContexts<unknown>>(
  createDefaultDnDContext<unknown>(),
);

export function Provider<T>({
  name,
  idKey,
  data,
  children,
}: PropsWithChildren<Props<T>>) {
  const dataValidation = (
    data: Record<string | symbol, unknown>,
    name: string,
  ) => {
    return data[name] === true;
  };

  const getData = (item: Record<string | symbol, unknown>) => {
    return { [name]: true, [idKey]: item[idKey], id: item[idKey], data: item };
  };

  const ctx: IDnDContexts<T> = {
    idKey,
    name: name,
    data: data,
    dataValidation,
    getData,
  };

  return <DndContext value={ctx}>{children}</DndContext>;
}

export const useDndContext = () => useContext(DndContext);

export default Provider;
