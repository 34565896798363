import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useToastMessage,
  ToastMessageTemplates as Templates,
} from '@dop-ui/react/shared/ui/toast';
import { postLinkShortcut } from '../api/createShortcutQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as getOrganizerQueryId } from '../../../apis/getOrganizerQuery';
import styles from '../shortcut.module.css';
import Button from '@dop-ui/react/shared/ui/button';

export function OrganizerLinkAdder() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { info } = useToastMessage();

  const [linkUrl, setLinkUrl] = useState('');

  const handleOnSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    setLinkUrl('');
    info(<Templates.Basic description={t('organizer.toast.add')} />);
  };

  const postLinkShortcutMutaion = useMutation({
    mutationFn: (url: string) => postLinkShortcut(url),
    onSuccess: () => {
      handleOnSuccess();
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleClickAdd(linkUrl);
    }
  };

  const handleClickAdd = (url: string) => {
    if (!url) {
      info(<Templates.Basic description={t('organizer.toast.url.empty')} />);
    } else {
      postLinkShortcutMutaion.mutate(url);
    }
  };

  return (
    <div className={styles.Card}>
      <p className={styles.Title}>{t('organizer.shortcut.link')}</p>

      <div className="flex items-center gap-2">
        <input
          className={styles.Input}
          type="text"
          title={t('organizer.input.link')}
          placeholder="http://"
          value={linkUrl}
          onKeyDown={handleOnKeyDown}
          onChange={(e) => setLinkUrl(e.target.value)}
        />
        <Button
          size="medium"
          variant="outline"
          shape="rect"
          colorset="level1"
          onClick={() => {
            handleClickAdd(linkUrl);
          }}
        >
          {t('organizer.add.button')}
        </Button>
      </div>
    </div>
  );
}

export default OrganizerLinkAdder;
