import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { useMessageDialog } from '@dop-ui/react/shared/ui/message-dialog';
import { TrashCanIcon } from '@dop-ui/icons/react/dop/16';
interface Props {
  onClickConfirm: () => void;
}
function DeleteMemoForm({ onClickConfirm }: Props) {
  const { t } = useTranslation();
  const { confirm } = useMessageDialog();

  const handleDeleteConfirm = async () => {
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">{t('organizer.memo.delete')}</h2>
          <p className="mb-2">{t('organizer.memo.alert.delete')}</p>
        </div>,
      )
    ) {
      onClickConfirm();
    }
  };

  return (
    <Button
      size="small"
      className="flex justify-center items-center gap-1 align-bottom p-1 bg-transparent"
      onClick={() => void handleDeleteConfirm()}
    >
      <TrashCanIcon size={16} className="text-[#E84B4B]" />
      <span className="text-[12px] text-[#E84B4B]">
        {t('organizer.memo.button.delete')}
      </span>
    </Button>
  );
}

export default DeleteMemoForm;
